@function rem($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}

:root {
  --color: #000000;
  --background-color: #fefefe;

  --gap: 15px;
  --gap-sm: 25px;
  --gap-md: 32px;
  --gap-lg: 90px;
  --gap-xl: 180px;
  --container-width: 1150px;
  --inner-column-width: 50%;
  --transition-time: 0.2s;
  --z-background: 0;
  --z-content: 1;
  --z-menu: 100;
}

.grecaptcha-badge {
  display: none !important;
}

.overflow-hidden {
  overflow: hidden;
}

a,
a:hover {
  color: inherit;
}

.primary-text {
  color: var(--primary-color);
}
