@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import "./css/styles.scss";

@function rem($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}

:root {
  --default-color: #33393e;
  --default-background: #edf1f4;

  --card-color: #33393e;
  --card-background: #ffffff;

  --primary-color: #0f66b7;
  --primary-text-color: #ffffff;

  --gap: #{rem(72)};
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  color: var(--default-color);
  background: var(--default-background);

  font-family: "Open Sans", sans-serif;

  counter-reset: counter;

  scroll-behavior: smooth;

  h1 {
    font-size: rem(44);
    font-weight: bold;
    letter-spacing: rem(-0.46);
    line-height: rem(52);
    margin: rem(36) 0;
  }

  h2 {
    font-size: rem(28);
    letter-spacing: 0;
    line-height: rem(38);
    font-weight: normal;
    margin: 0 0 rem(24);
  }

  h3 {
    font-size: rem(26);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(28);
  }

  h4 {
    font-size: rem(20);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(30.64);
    margin: 0;
  }

  p {
    font-size: rem(14);
    letter-spacing: 0;
    line-height: rem(24);
    margin: 0;
  }

  a,
  a:focus,
  a:hover {
    color: inherit;
    text-decoration: none;
  }

  #app {
    display: block;
    margin: 0 auto;
    width: rem(1280);
    max-width: 100%;

    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column-reverse;
    }

    main {
      margin: 0;
      padding: rem(46) rem(0) rem(52) rem(90);
      color: var(--card-color);
      background: var(--card-background);
      width: rem(781);
      max-width: 100%;
      header {
        display: flex;
        flex-direction: row;
        column-gap: rem(67);
        height: rem(42);
        figure {
          display: block;
          height: 100%;
          margin: 0;
          picture {
            display: block;
            height: 100%;
            img {
              display: block;
              position: relative;
              height: 100%;
            }
          }
        }
      }

      section {
        article {
          padding-top: var(--gap);
          &:nth-of-type(1) {
            h1 {
              width: rem(692);
              max-width: 100%;
            }
            p {
              width: rem(570);
              max-width: 100%;
              a {
                color: var(--primary-color);
                transition: color 0.3s;
                &:hover {
                  color: var(--default-color);
                }
              }
            }
          }
          &:nth-of-type(2) {
            ul {
              margin: 0;
              padding: rem(10) 0 0 rem(65);
              li {
                position: relative;
                list-style: none;
                display: flex;
                flex-direction: row;
                margin-bottom: var(--gap);
                summary {
                  width: rem(360);
                  max-width: 100%;
                  h2 {
                    color: var(--primary-color);
                    transition: color 0.3s;
                    &:hover {
                      color: var(--default-color);
                    }
                  }
                  p {
                    padding-right: rem(50);
                  }
                }
                figure {
                  display: block;
                  width: rem(260);
                  margin: 0 rem(-32) 0 0;
                  picture {
                    display: block;
                    width: 100%;
                    margin-left: rem(30);
                    img {
                      display: block;
                      position: relative;
                      width: 100%;
                    }
                  }
                }
                &::before {
                  counter-increment: counter;
                  content: counter(counter);
                  display: flex;
                  position: relative;
                  width: rem(32);
                  height: rem(32);
                  justify-content: center;
                  align-items: center;
                  border: rem(1.5) solid var(--default-color);
                  border-radius: 50%;
                  position: absolute;
                  top: rem(-0);
                  left: rem(-65);
                  font-size: rem(14);
                  font-weight: bold;
                  letter-spacing: 0;
                  line-height: rem(19);
                  text-align: center;
                }

                &:nth-of-type(3) {
                  h4 {
                    white-space: nowrap;
                  }
                  figure {
                    picture {
                      margin-top: rem(85);
                    }
                  }
                }
              }
            }
          }
          &:nth-of-type(3) {
            ul {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              column-gap: rem(24);
              row-gap: rem(47.71);
              margin: rem(41.71) 0 rem(47.71);
              padding: 0;
              li {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: rem(138);
                figure {
                  display: block;
                  width: rem(66);
                  margin: 0;
                  picture {
                    display: block;
                    width: 100%;
                    margin-bottom: rem(12.29);
                    img {
                      display: block;
                      position: relative;
                      width: 100%;
                    }
                  }
                  figcaption {
                    display: block;
                    position: relative;
                    margin: 0;
                    font-size: rem(14);
                    letter-spacing: 0;
                    line-height: rem(19);
                  }
                }
              }
            }
          }
        }
      }

      footer {
        width: rem(658);
        max-width: 100%;
        padding-top: var(--gap);
        p {
          margin-bottom: rem(22);
          line-height: rem(16);
        }
        .cookies {
          display: block;
          font-size: rem(10);
          letter-spacing: 0;
          line-height: rem(12);
          small {
            font-size: rem(10);
            letter-spacing: 0;
            line-height: rem(12);
          }
          .close {
            text-align: right;
            cursor: pointer;
          }
          &.hidden {
            display: none;
          }
        }
        summary {
          display: flex;
          flex-direction: row;
          column-gap: rem(60);
          height: rem(27);
          margin-top: rem(32);

          figure {
            display: block;
            height: 100%;
            margin: 0;
            picture {
              display: block;
              height: 100%;
              img {
                display: block;
                position: relative;
                height: 100%;
              }
            }
          }
        }
      }
    }
    aside {
      display: block;
      float: right;
      width: rem(480);
      max-width: 100%;
      text-align: center;
      ul {
        display: flex;
        flex-direction: column;
        row-gap: rem(22);
        position: relative;
        padding: 0;
        margin: rem(40) auto 0;
        width: rem(317.12);
        max-width: 100%;

        li {
          display: block;
          position: relative;
          background-color: var(--card-background);
          box-shadow: 0 rem(2) rem(29) 0 rgba(0, 0, 0, 0.1);
          figure {
            display: block;
            width: 100%;
            margin: 0;
            picture {
              display: block;
              width: 100%;
              padding: rem(14) 0;
              img {
                display: block;
                position: relative;
                width: rem(200);
                margin: 0 auto;
              }
            }
            figcaption {
              display: block;
              text-align: center;
              font-size: rem(14);
              font-weight: bold;
              letter-spacing: 0;
              line-height: rem(41);
              border-top: rem(1) solid #e2e3e4;
              color: var(--primary-text-color);
              background-color: var(--primary-color);
              transition: background-color 0.3s;
              &:hover {
                background-color: var(--default-color);
              }
              a {
                width: 100%;
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
